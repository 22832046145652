@tailwind base;
@tailwind components;
@tailwind utilities;

@import '../node_modules/react-big-calendar/lib/css/react-big-calendar.css';
/* @import "react-big-calendar/lib/addons/dragAndDrop/styles"; */

.custom-height {
	/* background-color: red; */
	height: 800px;
}

.rbc-event {
	background-color: red;
	border-radius: 0;
	margin: 0;
	padding: 0;
	height: 25px;
	width: 25px;
	overflow: hidden;
	border: 1px solid white;
}
.rbc-time-view .rbc-row {
	min-height: 0px;
}

.rbc-time-view .rbc-row div:nth-child(1) {
	height: 0px !important;
}
