.input-file-live__wraper {
	position: relative;
	width: 100%;
	border: 1px solid #c1c1c1;
	color: gray;
	background-color: #fff;
	height: 40px;
	overflow: hidden;
}

.input-file-live__wraper input {
	opacity: 0;
	height: 100%;
	position: absolute;
	z-index: 2;
	width: 100%;
	cursor: pointer;
	top: 0px;
	left: 0px;
}

a {
	color: black;
	text-decoration: none;
}
.input-file-live__wraper .input-file-live__label {
	padding: 0rem 0.5rem;
}

.input-file-live__wraper .input-file-live {
	padding: 0px;
	opacity: 1;
	height: 100%;
	position: absolute;
	z-index: 1;
	width: 100%;
	cursor: pointer;
	display: flex;
	align-items: center;
}

.input-file-live__wraper .input-file-live__full {
	display: none;
	z-index: 3;
	padding: 0rem 0.5rem;
	opacity: 1;
	height: 100%;
	position: absolute;
	width: 100%;
	display: flex;
	background-color: #dcffdc;
	align-items: center;
	justify-content: space-between;
}

.input-file-live--empty {
	display: flex !important;
}

.input-file-live--full {
	display: none !important;
}
